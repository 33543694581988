import { createGlobalStyle, keyframes } from 'styled-components'
import constants from 'styles/constants'

import AtlasGrotesk from "../fonts/AtlasGrotesk-Regular.otf";
import EditorialNew from "../fonts/EditorialNew-Regular.otf";

const scrollIn = keyframes`
    0%  {
        transform: translateY(100px);
    }
   
    100% {
        transform: translateY(0px);
    }
`

const scrollOut = keyframes`
    0%  {
        transform: translateY(0px);
    }
   
    100% {
        transform: translateY(100px);
    }
`


const Index = createGlobalStyle`
    @font-face {
        font-family: 'AtlasGrotesk';
        src: url(${AtlasGrotesk}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'EditorialNew';
        src: url(${EditorialNew}) format('opentype');
        font-weight: 400;
        font-style: normal;
    }

    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    :root {
        font-family: 'AtlasGrotesk';
        font-weight: 400;
        font-size: 20px;
        line-height: 1.16;
        color: #9491ff;
        overflow-y: scroll;

        @media (min-width: ${constants.breakpoint.sm}px) {
            font-size: 25px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0%;
        font-weight: 400;
    }

    a {
        text-decoration: none;
    }

    h2 {
        font-family: 'EditorialNew';
        text-align: center;
        font-size: 66px;
        line-height: 1.56;
        margin-top: 50px;

        @media (min-width: ${constants.breakpoint.sm}px) {
            margin-top: 60px;
        }

        @media (min-width: ${constants.breakpoint.md}px) {
            margin-top: 80px;
            font-size: 100px;
        }
    }

    h3 {
        font-family: 'EditorialNew';
    }

    main {
        position: relative;
        z-index: 1;

        .scrollIn {
            transform: translateY(100px);
            animation: ${scrollOut} 2s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;

            &.show {
                animation: ${scrollIn} 2s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
            }
        }
      
    }

    #app {
        margin: 0 30px;
    }

    p + p {
        margin-top: 29px;
    }

    a.more,
    span.more {
        font-family: 'EditorialNew';
        font-size: 20px;
        cursor: pointer;

        @media (min-width: ${constants.breakpoint.sm}px) {
            font-size: 25px;
        }
    }
`
export default Index;