import { useState, useEffect } from 'react'

const useIsVisible = ({ ref, rootMargin = 50, setInvisible = false }) => {
    const [visible, setVisisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                console.log(entry);

                if (entry.isIntersecting) {
                    console.log('visible')
                    setVisisible(true)
                    
                    if(!setInvisible) {
                        observer.disconnect();
                    }
                
                } else {
                    console.log('not visible')
                    setVisisible(false)
                }
            },
            {
                root: null,
                rootMargin: `${rootMargin}px`
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
    }, [ref]);

    return visible
}

export default useIsVisible