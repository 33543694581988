import React, { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import LazyImage from 'components/lazyImage'
import constants from 'styles/constants'

const fadeIn = keyframes`
    0% { 
        opacity: 0.3;
        transform: scale(1.8);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: none;
    }
`

const fadeOut = keyframes`
    0%  {
        opacity: 1;
        transform: none;
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    } 
`

const StyledOverlay = styled.div`
    position: fixed;
    z-index: 200;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.89);
    animation: ${fadeIn} 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    will-change: opacity, transform;
    --slideMarginX: 30px;

    @media (min-width: ${constants.breakpoint.md}px) {
        --slideMarginX: 60px;
    }

    @media (min-width: ${constants.breakpoint.lg}px) {
        --slideMarginX: 120px;
    }

    &.out {
        animation: ${fadeOut} 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards;
    }

    .slides {
        position: absolute;
        left: calc(var(--slideMarginX) * 2);
        top: 120px;
        bottom: 120px;
        width: calc(100% - var(--slideMarginX) * 4);

        > div {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

            &.noanimate {
                transition-duration: 0s;

                > div {
                    .image {
                        > div {
                            transition-duration: 0s
                        }
                    }
                }
            }

            > div {
                position: absolute;
                width: 100%;
                height: 100%;
                color: #fff;
                transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

                .image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
                    background-position: center center;

                    > div {
                        left: 0;
                        top: 0;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        transition-duration: 0s;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
        }
    }

    h3,
    .close {
        position: absolute;
        font-family: 'EditorialNew';
        font-size: 25px;
        bottom: 40px;
        color: ${constants.colors.green};
    }

    h3 {
        left: 50%;
        transform: translateX(-50%);
    }

    .close {
        right: 50px;
        cursor: pointer;
    }
`
const Overlay = ({ active, data, title }) => {
    const location = useLocation();
    const history = useHistory()

    const [visible, setVisible] = useState(false)
    const [currentSlide, setCurrentSlide] = useState(null)
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    const ref = useRef()
    const [ slideMarginX, setSlideMarginX ] = useState(60)

    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const skipAnimate = useRef(null)

    useEffect(() => {
        const resize = () => {
            skipAnimate.current = true
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        resize();

        window.addEventListener('resize', resize)

        return () => {
            window.removeEventListener('resize', resize)
        }
    }, [ref])

    useEffect(() => {
        console.log('test margin')
        if(ref.current) {
            console.log('set margin')
            const itemsPerSlideNew = getComputedStyle(ref.current).getPropertyValue('--slideMarginX')
            
            console.log(itemsPerSlideNew)
            setSlideMarginX(parseInt(itemsPerSlideNew))
        }

    }, [ref.current, dimensions, visible])


    const keyPressHandler = (event) => {
        console.log(event.keyCode)

        switch (event.keyCode) {
            case 27:
                history.goBack()
                break;

            case 37:
                setCurrentSlide(currentSlide - 1)
                break;

            case 39:
                setCurrentSlide(currentSlide + 1)
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', keyPressHandler);

        return () => {
            document.removeEventListener('keydown', keyPressHandler);
        }
    }, [currentSlide])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const i = parseInt(params.get('i'))


        let index = data.findIndex(image => image.ID === i);

        if (index > -1) {

            //console.log('SETED ' + index);

            setCurrentSlide(index)

            //this.props.setClosed(false)
        }

    }, [location.search])


    useEffect(() => {
        if (active && !visible) {
            setVisible(true)
        }

        if (!active && visible) {
            setTimeout(() => {
                setVisible(false)
            }, 500);
        }
    }, [active, visible])

    const getImage = (slide) => {
        let test = slide
        let test2 = test % data.length;

        if (test2 < 0) {
            test2 = data.length + test2
        }

        return test2
    }

    if (!visible) return null

    let slides = []

    

    

    let start = currentSlide - 2;
    if(start < 0) start = 0;

    let end = currentSlide + 2;
    if(end > data.length-1) end = data.length-1;

    for (let i = start; i <= end; i++) {
        let left = i * 100;

        let item = data[getImage(i)]

        let className = i < currentSlide ? 'previous' : i > currentSlide ? 'next' : null

        let extraLeft = 0

        const scaleWidth = (dimensions.width - slideMarginX*4) / item.width
        const scaleHeight = (dimensions.height - slideMarginX*2) / item.height

        let scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight


        if (scaleWidth > scaleHeight) {
            extraLeft = ((dimensions.width - slideMarginX*4) - (scale * item.width)) / 2
            scale = 'height fitted' + scale


        } else {
            scale = 'width fitted' + scale
        }

        extraLeft -= slideMarginX

        if (i === currentSlide) {
            extraLeft = 0
        }

        //console.log(extraLeft)

        if (i < currentSlide) {
            extraLeft = extraLeft * -1
        }

        //console.log(extraLeft)

        //extraLeft = extraLeft * -1


        slides.push(
            <div className={className} style={{ left: `${left}%` }} key={i}>
                <div
                    className="image"
                    style={{ backgroundImage: `url(${item.preview})`, transform: `translateX(${-1 * extraLeft}px)` }}
                    onClick={() => {
                        const newSlide = i < currentSlide ? currentSlide - 1 : currentSlide + 1

                        if(i!==currentSlide) {
                            setCurrentSlide(newSlide)
                        }
                    }}
                    >
                    {(i === currentSlide) &&
                        <LazyImage
                            image={item.path}
                            style={{ backgroundImage: `url(${item.path})` }}
                        />
                    }
                </div>
            </div>
        )
    }
    

    const classes = [active ? 'in' : 'out']
    if(skipAnimate.current || touching.isTouching) classes.push('noanimate')

    skipAnimate.current = false

    let translateX = -currentSlide * 100;

    if (touching.isTouching) {
        translateX += (touching.touchX / window.innerWidth) * 100;
    }

    return createPortal(
        <StyledOverlay className={classes} ref={ref}>
            <div
                className="slides"
            >
                <div style={{ transform: `translateX(${translateX}%)` }}>
                    {slides}
                </div>
            </div>
            <h3>{title}</h3>
            <span className="close" onClick={() => history.goBack()}>(Close)</span>
        </StyledOverlay>,
         document.getElementById("app")
    );
};

export default Overlay;