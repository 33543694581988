import Header from './components/header'
import About from './components/about'
import News from './components/news'
import Projects from './components/projects'
import Footer from './components/footer'
import GlobalStyles from './styles';
import { useLocation } from 'react-router'
import { useState, useEffect } from 'react'

const URL = `https://s-o-f-t.agency/wordpress/api/`;

const fetchData = async () => {
  const response = await fetch(URL);
  const data = await response.json();
  if (response.status >= 400) {
    throw new Error(data.errors);
  }
  return data;
};

function App() {
  const [data, setData] = useState(null)
  const [readability, setReadability] = useState(false);

  useEffect(() => {
    (async () => {
      const test = await fetchData()
      setData(test)
    })()
  }, [])

  console.log('readability', readability)

  return (
    <div id="app">
      <GlobalStyles />
      <Header
        readability={readability}
        toggleReadability={() => setReadability(!readability)}
        data={data?.global}
      />
      {data &&
        <>
          <main>
            <About
              data={data.about}
              readability={readability}
            />
            <News
              data={data.news}
              readability={readability}
            />
            <Projects
              data={data.projects}
              readability={readability}
            />
          </main>
          <Footer data={data?.global} />
        </>
      }
    </div>
  );
}

export default App;
