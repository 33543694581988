import React, { createRef, useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import Project from './Project'
import constants from 'styles/constants'
import useWindowSize from 'hooks/useWindowSize'
import useIsVisible from 'hooks/useIsVisible';

const Section = styled.section`
    &,
    a {
        color: ${props => !props.readability ? constants.colors.green : constants.colors.grey};
    }

    h3 {
        text-align: center;
        font-size: 40px;
        line-height: 1.05;
        margin-top: 10px;

        @media (min-width: ${constants.breakpoint.sm}px) {
            font-size: 52px;
        }

        @media (min-width: ${constants.breakpoint.lg}px) {
            font-size: 78px;
        }
    }

    > div {
        display: flex;
        justify-content: space-between;

        > div {
            flex: 100%;
            
            > div + div {
                margin-top: 60px;
            }
        }

        &.cols2 {
            > div {
                flex: 0 0 calc(50% - 30px);
            }
           
        }
    }

`

const Projects = ({ data, readability }) => {
    const ref = useRef()
    const { width } = useWindowSize();
    const visible = useIsVisible({ ref })

    const itemRefs = data.map((item => {
        return createRef();
    }));

    const [projects, setProjects] = useState([]);

    const calculateProjects = () => {

        const columnCount = width && width > 768 ? 2 : 1

        const columns = Array();
        const columnHeights = Array();

        [...Array(columnCount).keys()].forEach(index => {
            columns.push([])
            columnHeights.push(0)
        });

        data.forEach((item, index) => {
            const height = itemRefs[index].current ? itemRefs[index].current.clientHeight : 10
            const columnIndex = columnHeights.indexOf(Math.min.apply(null, columnHeights))

            columns[columnIndex].push(
                <Project
                    key={index}
                    data={item}
                    ref={itemRefs[index]} />
            )

            columnHeights[columnIndex] += height
        })

        console.log(columnHeights)

        return columns

        /*
        return <>
            {columns.map((column, index) => (
                <div key={index}>{column}</div>)
            )}
        </>
        */
    }

    useEffect(() => {
        setProjects(calculateProjects())
    }, [width]);

    const columns = projects.length>0 ? projects.length : 1

    return (
        <Section 
            ref={ref} 
            readability={readability}
            
        >
            <h2>Projects</h2>
            <div className={`cols${columns}`}>
                {(projects.length>0) ? (
                    projects.map((column, index) => (
                        <div key={index}>{column}</div>
                    ))
                ) : (
                    data.map((item, index) => (
                        <Project key={index} data={item} ref={itemRefs[index]} />
                    ))
                )}
            </div>
        </Section>
    );
};

export default Projects;