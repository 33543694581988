import React, { useRef } from 'react';
import styled from 'styled-components'
import constants from 'styles/constants'
import useIsVisible from 'hooks/useIsVisible';

const Section = styled.section`
    &,
    a {
        color: ${props => !props.readability ? constants.colors.green : constants.colors.grey};
    }

    > div {
        columns: 1;
        margin-bottom: -30px;

        @media (min-width: ${constants.breakpoint.md}px) {
            columns: 2;
        }
    }

    .item {
        position: relative;
        padding-left: 85px;
        break-inside: avoid;
        margin-bottom: 30px;

        @media (min-width: ${constants.breakpoint.sm}px) {
            padding-left: 110px;
        }

        > .date {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
`

const News = ({data, readability}) => {
    const ref = useRef();
    const visible = useIsVisible({ ref })

    return (
        <Section
            ref={ref} 
            readability={readability}
            className={'scrollIn' + (visible ? ' show' : '')}
        >
            <h2>News</h2>
            <div>
                {data.map((item, index) => (
                    <div className="item">
                        <span className="date">{item.date}</span>
                        <h3>{item.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: item.text}} />
                    </div>
                ))}
            </div>
        </Section>
    );
};

export default News;