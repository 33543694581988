import React, { forwardRef } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Overlay from 'components/overlay'
import constants from 'styles/constants'
import useIsVisible from 'hooks/useIsVisible';

const StyledProject = styled.div`
    font-size: 18px;
    line-height: 1.31;

    @media (min-width: ${constants.breakpoint.sm}px) {
        font-size: 22px;
    }

    .image {
        position: relative;
        z-index: 3;
        padding-bottom: 65.45%;
        background-image: ${props => `url(${props.image})`};
        background-size: cover;
        background-position: center center;
        clip-path: ${props => `url(#ImageMask${props.mask})`};
    }

    .info {
        margin-top: 30px;
        
        div {
            display: flex;
            justify-content: space-between;

            span + span {
                text-align: right;
            }
        }
    }
`

const Project = forwardRef((props, ref) => {
    let location = useLocation();
    let params = new URLSearchParams(location.search)
    const visible = useIsVisible({ ref })
    
    const { data } = props;
    const image = data.images[0];

    return (
        <>
            <StyledProject
                ref={ref}
                mask={data.mask}
                image={image.preview}
                className={'scrollIn' + (visible ? ' show' : '')}
            >
                <Link to={`?p=${data.ID}&i=${image.ID}`}>
                    <div className="image" />
                </Link>
                <div className="info">
                    {data.infos.map((item, index) => (
                        <div key={index}>
                            <span>{item.left}</span>
                            <span>{item.right}</span>
                        </div>
                    ))}
                </div>
                <h3>{data.title}</h3>
                <div className="text" dangerouslySetInnerHTML={{ __html: data.text }} />
            </StyledProject>
            <Overlay
                data={data.images}
                title={data.title}
                active={parseInt(params.get('p'))===data.ID} /> 
        </>
    )
});

export default Project;