import { useRef } from 'react'
import  { useState, useLayoutEffect, useEffect } from 'react'

function usePreload({ref, image}) {
    const [loaded, setLoaded] = useState(false)
    
    const isLoading = useRef(false)

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            console.log(entry);
    
            if (entry.isIntersecting) {
              
                var i = new Image();
                i.onload = () => {
                    //console.log('loaded')


                    setLoaded(true)
                };
                i.src = image;
                
                observer.disconnect();
            }
          },
          {
            root: null,
            rootMargin: "0px"
          }
        );
        if (ref.current) {
          observer.observe(ref.current);
        }
    }, [ref]);


    return { loaded }
}

function usePreload2({ref, image}) {
	const [loaded, setLoaded] = useState(false)
    
    const isLoading = useRef(false)

	/*
	useEffect(() => {
	
	}, [ref, top])
	*/
	useLayoutEffect(() => {
		//if (ref.current) {

        setTimeout(() => {
            isVisible()
        }, 0);
        
        window.addEventListener('scroll', isVisible)
        window.addEventListener('resize', isVisible)
		//}
			
		return () => {
            window.removeEventListener('scroll', isVisible)
            window.removeEventListener('resize', isVisible)
        }
    })
	

	const isVisible = () => {
		if(ref && !ref.current) return

        const scrollTop = window.scrollY || document.documentElement.scrollTop
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

		const elementY = ref.current.getBoundingClientRect().y || ref.current.getBoundingClientRect().top

		//console.log(scrollTop, height)
		let elementTop = elementY + scrollTop
        
        
        //console.log(scrollTop, windowHeight, ' / ', elementY, scrollTop)
        
        if(scrollTop+windowHeight>elementTop) { 

            if(!isLoading.current) {
                isLoading.current = true

                //console.log('try load')

                var i = new Image();
                i.onload = () => {
                    //console.log('loaded')
                    setLoaded(true)
                };
                i.src = image;
            }

            
        }

        /*
		if((scrollTop+height+400)>top) { 
			setLoaded(true)
			//window.removeEventListener('scroll', isVisible)
		}	else {
			//console.log('load not')
		}

		if((scrollTop+height-200)>top) { 
			setVisible(true)
			window.removeEventListener('scroll', isVisible)
        }	
        */
	}

	return { loaded }
}

export {
    usePreload
}