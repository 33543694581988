import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import constants from 'styles/constants'
import Button from 'components/button'

const StyledHeader = styled.header`
    position: relative;
    padding-bottom: 28%;
    margin: 30px 0;
    transition: all 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);

    .logo {
        z-index: 2;
        position: fixed;
        width: calc(100% - 60px);
        padding-bottom: 28%;
        left: 30px;  
        pointer-events: none;
        mix-blend-mode: difference;
        

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
             
    }

    svg {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        transition: all 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940);
        
        path {
            fill: ${constants.colors.purple};
            stroke: ${constants.colors.purple};
           
        }
    }

    &.readability {
        padding-bottom: 100px;

        .logo {
            position: absolute;

            svg {
                width: 400px;
                
                path {
                    fill: ${constants.colors.grey};
                    stroke: ${constants.colors.grey};
                }
            }
        }
    }

    .navigation {
        position: fixed;
        z-index: 10;
        right: 30px;
        top: 30px;
        
        > * + * {
            margin-left: 6px;
        }
    }
`

const Header = ({ readability, toggleReadability, data }) => {
    const [scrollY, setScrollY] = useState(false)

    const [frame, setFrame] = useState(0)

    const video = useRef();

  
    useEffect(() => {
        const interval = setInterval(() => {
            
            const scrollYNew = Math.floor(window.scrollY / 10) + 1
            
            //setScrollY(scrollYNew)
            

            let frameNew = frame

            if(frameNew<scrollYNew) {
                frameNew += 1

                

            } else if(frameNew>scrollYNew) {
                frameNew -= 1
            }

            
            if(frameNew>51) frameNew=51

            console.log(frameNew)

            //console.log(frameNew)

        
            setFrame(frameNew)

        }, 20)
    
        return () => {
            clearInterval(interval);
        }
    }, [frame])

 

    


   

    let multiplier = scrollY / 200;
    if (multiplier > 1) multiplier = 1

    //const style = { filter: `blur(${multiplier * 6}px)`, strokeWidth: `${multiplier * 40}px`, opacity: 1 - (multiplier * 0.5) }
    //const style = { filter: `blur(${multiplier * 10}px)`, strokeWidth: `${multiplier * 40}px`, opacity: 1 - (multiplier * 0.1) }
    const style = { strokeWidth: `${multiplier * 40}px`, opacity: 1 }
    //const style = { }

    const images = [...Array(51).keys()].map(index => {

        index = index+1
        const number = index < 10 ? `0${index}` : index

        console.log(index >= frame)
        
        return (
            <img 
                style={{opacity: index === frame ? 1 : 0}}
                key={index}
                src={`${process.env.PUBLIC_URL}/logo3/LogoBlend_SoftAgency-${number}.svg`} 
            />
        )
    })

    console.log('render')

    return (
        <StyledHeader className={readability ? 'readability' : ''}>
            <div className="logo">
                {images}
            </div>
            <div className="navigation">
                {data?.email && 
                    <Button href={`mailto:${data.email}`}>Contact</Button>
                }
                <Button as="span" onClick={() => toggleReadability()}>Aa+</Button>
            </div>
        </StyledHeader>
    );
};

export default Header;