import React, { useRef } from 'react';
import styled from 'styled-components'
import Button from 'components/button'

const StyledFooter = styled.header`
    height: 100px;

    svg {
        position: fixed;
        left: -100000px;
    }

    div {
        margin-top: 30px;
        
        * + * {
            margin-left: 6px;
        }
    }
`

const Footer =  ({ data }) => {
    let intervalId = useRef(null)

    const scrollStep = () => {
    	if (window.pageYOffset === 0) {
        	clearInterval(intervalId);
    	}
    	window.scroll(0, window.pageYOffset - 150);
	}

    const scrollToTop = () => {
    	intervalId = setInterval(scrollStep, 16.66);
	}

    return (
        <StyledFooter>
            <div>
                <Button onClick={() => scrollToTop()}>Start Again</Button>
                {data?.email &&
                    <Button href={`mailto:${data.email}`}>Contact</Button>
                }
            </div>
            <svg width="878.09" height="575.4" viewBox="0 0 878.09 575.4">
                <clipPath id="ImageMask1" clipPathUnits="objectBoundingBox">
                    <path d="M0.464,0 A0.145,0.221,0,0,0,0.338,0.219 v0.175 a0.047,0.071,0,0,1,-0.047,0.071 h-0.118 A0.175,0.268,0,0,0,0,0.69 v0.085 A0.175,0.268,0,0,0,0.173,1 h0.651 a0.175,0.268,0,0,0,0.175,-0.268 h0 a0.175,0.267,0,0,0,-0.074,-0.218 A0.144,0.22,0,0,0,1,0.322 V0.219 A0.145,0.221,0,0,0,0.874,0" />
                </clipPath>
                <clipPath id="ImageMask2" clipPathUnits="objectBoundingBox">
                    <path d="M0.691,0.036,0.476,0.337 s-0.021,0.038,-0.06,0.038 c-0.077,0,-0.084,-0.096,-0.084,-0.125 V0.063 S0.333,0,0.292,0 H0.042 S0,0,0,0.063 v0.562 s0,0.125,0.083,0.125 l0.125,0 a0.042,0.065,0,0,1,0.029,0.018 c0.013,0.02,0.095,0.158,0.12,0.195 s0.059,0.037,0.059,0.037 H0.917 s0.083,0,0.083,-0.125 V0.063 S1,0,0.958,0 H0.75 c-0.003,0,-0.036,0.001,-0.059,0.036" />
                </clipPath>
                <clipPath id="ImageMask3" clipPathUnits="objectBoundingBox">
                    <path d="M0.584,0 C0.558,0,0.535,0.013,0.517,0.05 c-0.036,0.074,-0.052,0.199,-0.1,0.199 c-0.031,0,-0.067,-0.062,-0.118,-0.149 C0.237,-0.008,0.167,0.002,0.167,0.002 C0.007,0.002,0,0.252,0,0.252 v0.375 c0,0.039,0.021,0.082,0.021,0.082 s0.147,0.222,0.17,0.256 s0.039,0.035,0.058,0.035 h0.674 c0.056,0,0.077,-0.071,0.077,-0.099 V0.125 C1,0.047,0.938,0,0.912,0" />
                </clipPath>
                <clipPath id="ImageMask4" clipPathUnits="objectBoundingBox">
                    <path d="M0.541,0 H0.25 C0.043,0,0,0.313,0,0.375 V0.875 c0,0.115,0.083,0.125,0.083,0.125 s0.778,0,0.834,0 S1,0.927,1,0.875 V0.5 c0,-0.089,-0.055,-0.127,-0.083,-0.127 H0.75 c-0.066,0,-0.084,-0.104,-0.083,-0.125 C0.667,0.004,0.564,0,0.543,0 h-0.002"  />
                </clipPath>
                <clipPath id="ImageMask5" clipPathUnits="objectBoundingBox">
                    <path d="M0.333,0 C0.249,0,0,0.094,0,0.499 S0.25,0.999,0.333,0.999 c0.07,0,0.461,0.001,0.587,0.001 A0.083,0.125,0,0,0,0.932,0.752 H0.666 s-0.083,-0.01,-0.083,-0.127 s0.077,-0.125,0.083,-0.125 s0.149,0,0.167,0 s0.166,-0.02,0.166,-0.25 S0.862,0,0.833,0"  />
                </clipPath>                
            </svg>
        </StyledFooter>
    );
};

export default Footer;