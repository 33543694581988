const constants = {
    colors: {
        purple: '#9792FF',
        green: '#789849',
        grey: '#4C4C4C'
    },
    
    breakpoint: {
        xxs: 0,
        xs: 360,
        sm: 576,
        md: 768,
        lg: 1024,
    },
}

export default constants;