import React, { useState, useRef } from 'react';
import styled from 'styled-components'
import constants from 'styles/constants'
import useIsVisible from 'hooks/useIsVisible';

const Section = styled.section`
    &,
    a {
        color: ${props => !props.readability ? constants.colors.purple : constants.colors.grey};
    }

    h3 {
        font-size: 50px;
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .colums,
    .people {
        columns: 1;
        column-gap: 60px;

        @media (min-width: ${constants.breakpoint.md}px) {
            columns: 2;
        }
    }

    .more {
        margin-top: 60px;
        height: 0;
        overflow: hidden;
        transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);

        > div {
            > div + div {
                margin-top: 30px;
            }
        }
    }

    .people {
        margin-bottom: -30px;

        > div {
            break-inside: avoid;
            font-size: 20px;
            margin-bottom: 30px;

            @media (min-width: ${constants.breakpoint.sm}px) {
                font-size: 24px;
            }
           
            h4 {
                font-family: 'EditorialNew';
                display: inline;
                font-size: 21px;

                @media (min-width: ${constants.breakpoint.sm}px) {
                    font-size: 25px;
                }
            }

            p {
                display: inline;
            }
        }
    }
`


const About = ({data, readability}) => {
    const [ open, setOpen ] = useState(false)
    const refMore = useRef();
    const ref = useRef();
    const visible = useIsVisible({ ref })

    const onTextClick = (event) => {    
        if(event.target.tagName === 'SPAN') {
            setOpen(!open)
        }
    }

    //const intro = data.intro + ' <span>(...)</span>'

    const moreHeight = open && refMore ? refMore.current.clientHeight : 0

    return (
        <Section
            ref={ref}
            readability={readability}
            className={'scrollIn' + (visible ? ' show' : '')}
        >
            <div
                className="intro colums"
                onClick={onTextClick} 
                dangerouslySetInnerHTML={{__html: data.intro }}
            />
            <div className="more" style={{height: `${moreHeight}px`}}>
                <div ref={refMore}>
                    {data.more.map((item, index) => (       
                        <div>
                            {item.title &&
                                <h3>{item.title}</h3>
                            }
                            {(item.type==="text") && 
                                <div 
                                    className="colums" 
                                    dangerouslySetInnerHTML={{__html: item.text }}
                                />
                            }
                            {(item.type==="people") &&
                                <div className="people">
                                    {item.items.map((item, index) => (
                                        <div><h4>{item.name}</h4> <p dangerouslySetInnerHTML={{__html: item.text}} /></div>
                                    ))}
                                </div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        </Section>
    );
};

export default About;