import React from 'react';
import styled from 'styled-components'

const StyledButton = styled.a`
    display: inline-block;
    height: 23px;
    padding: 4px 9px 0 9px;
    background: rgba(0,0,0,0.7);
    border-radius: 10.5px;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    line-height: 18px;
`

export default StyledButton;